import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { SessionProvider } from 'contexts/session';
import { ExperienceProvider } from 'contexts/experience';
import App from './app';
import './index.scss';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <SessionProvider>
                <ExperienceProvider>
                    <App />
                </ExperienceProvider>
            </SessionProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
