import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE;

export const requestSession = async ({ brand }) =>
    axios
        .post(`${BASE_URL}/session`, { brand }, { withCredentials: true })
        .then(({ data }) => data);

export const uploadPhoto = async (image) =>
    axios
        .post(`${BASE_URL}/media/photos`, { image }, { withCredentials: true })
        .then(({ data }) => data);

export default { requestSession, uploadPhoto };
