import React, { createContext, useContext, useMemo, useReducer } from 'react';
import * as client from './client';

const SessionContext = createContext();

const initReducer = () => ({
    error: false,
    prioritizedUploads: [],
    session: null,
});

const reducer = (state, { action, value }) => {
    switch (action) {
        case 'setPrioritizedUploads': {
            return { ...state, prioritizedUploads: value };
        }
        case 'setError':
            return { ...state, error: value };
        case 'setSession':
            return { ...state, session: value };
        default:
            throw new Error(`Unexpected action type ${action}`);
    }
};

export const SessionProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, props, initReducer);
    const value = useMemo(() => [state, dispatch], [state]);
    return <SessionContext.Provider value={value} {...props} />;
};

export const useSession = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used inside an SessionProvider');
    }

    const requestSession = async ({ brand }) =>
        client
            .requestSession({ brand })
            .then((session) =>
                dispatch({ action: 'setSession', value: session })
            )
            .catch((error) => {
                dispatch({ action: 'setError', value: true });
                throw error;
            });

    const uploadPhoto = async (image) => client.uploadPhoto(image);

    const [state, dispatch] = context;

    return {
        state,
        dispatch,
        requestSession,
        uploadPhoto,
    };
};

export default { useSession, SessionProvider };
