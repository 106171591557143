import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE;

export const fetchExperience = async ({ brand }) =>
    axios
        .get(`${BASE_URL}/experience/${brand}`, { withCredentials: true })
        .then(({ data }) => data);

export default { fetchExperience };
