import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './spinner.scss';

const Spinner = ({ fixed }) => (
    <div className={classNames('spinner', { fixed })}>
        <CircularProgress />
    </div>
);

Spinner.propTypes = {
    fixed: PropTypes.bool,
};

Spinner.defaultProps = {
    fixed: false,
};

export default Spinner;
