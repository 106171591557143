import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSession } from 'contexts/session';
import { useExperience } from 'contexts/experience';
import Spinner from 'components/Spinner';
import './app.scss';

const LoadableErrorPage = lazy(() => import('features/ErrorPage'));
const LoadableOnboarding = lazy(() => import('features/Onboarding'));

const App = () => {
    const {
        state: { error, session },
        requestSession,
    } = useSession();
    const {
        state: { brand, loading: experienceLoading },
        fetchExperience,
    } = useExperience();

    useEffect(() => {
        requestSession({ brand }).then(fetchExperience);
    }, [brand]);

    // if fetching session + experience and there's not yet an error in doing so, show a spinner
    if ((!session || experienceLoading) && !error) return <Spinner fixed />;

    return (
        <div className="app">
            <Suspense fallback={<Spinner fixed />}>
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={
                            error ? LoadableErrorPage : LoadableOnboarding
                        }
                    />
                </Switch>
            </Suspense>
        </div>
    );
};

export default App;
