import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as client from './client';

const ExperienceContext = createContext();

const initReducer = ({ brand }) => ({
    brand,
    loading: true,
});

const reducer = (state, { action, value }) => {
    switch (action) {
        case 'setExperience':
            return { ...state, ...value, loading: false };
        default:
            throw new Error(`Unexpected action type ${action}`);
    }
};

export const ExperienceProvider = (props) => {
    const { search } = useLocation();
    const { brand } = useMemo(() => queryString.parse(search), [search]);
    const [state, dispatch] = useReducer(
        reducer,
        { ...props, brand },
        initReducer
    );
    const value = useMemo(() => [state, dispatch], [state]);

    return <ExperienceContext.Provider value={value} {...props} />;
};

export const useExperience = () => {
    const context = useContext(ExperienceContext);
    if (!context) {
        throw new Error(
            'useExperience must be used inside an ExperienceProvider'
        );
    }

    const [state, dispatch] = context;

    const fetchExperience = async () => {
        const { brand } = state;
        return client
            .fetchExperience({ brand })
            .then((experience) =>
                dispatch({ action: 'setExperience', value: experience })
            );
        // TODO: catch and redirect to 404 or homepage...
    };

    return {
        state,
        dispatch,
        fetchExperience,
    };
};

export default { useExperience, ExperienceProvider };
